import React, { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	Col,
	Badge,
} from "reactstrap";
import { toast } from "react-toastify";
import "./B2SDashboard.css";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant2";
//Material UI table
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import LoadingModal from "../../../layout/LoadingModal/LoadingModal";

toast.configure();

const B2SDashboard = () => {
	//base url
	var url = config.url.API_URL;
	const [isloading, setIsloading] = useState(false);
	const [modalData2, setModalData2] = useState({});
	const [formSubmitModal, setFormSubmitModal] = useState(false);
	const [otherModal, setOtherModal] = useState(false);
	const [data, setData] = useState([]);
	// const [displayData, setDisplayData] = useState([]);
	const [totalWksht, setTotalWksht] = useState(0);

	const toggleOtherModal = () => setOtherModal(!otherModal);
	const toggleFormSubmitModal = () => setFormSubmitModal(!formSubmitModal);

	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Delete: forwardRef((props, ref) => (
			<DeleteOutline {...props} ref={ref} />
		)),
		DetailPanel: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		FirstPage: forwardRef((props, ref) => (
			<FirstPage {...props} ref={ref} />
		)),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		NextPage: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		PreviousPage: forwardRef((props, ref) => (
			<ChevronLeft {...props} ref={ref} />
		)),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		SortArrow: forwardRef((props, ref) => (
			<ArrowDownward {...props} ref={ref} />
		)),
		ThirdStateCheck: forwardRef((props, ref) => (
			<Remove {...props} ref={ref} />
		)),
		ViewColumn: forwardRef((props, ref) => (
			<ViewColumn {...props} ref={ref} />
		)),
	};

	const notifySuccess = (success) =>
		toast.success(success, {
			position: "top-center",
			autoClose: 5000,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: 5000,
		});

	const getUser = () => {
		axios
			.get(`${url}/GeTables`)
			.then((res) => {
				// console.log(res.data);
				setData(res.data.data);
				// setDisplayData(res.data.data);
				setTotalWksht(res.data.data.length);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// HANDLE ONCHANGE ACTION FOR ADDING NEW ACTIVITY
	const handleChange_2 = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setModalData2({ ...modalData2, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FORM SUBMIT FOR ADDING NEW ACTIVITY
	const handleSubmit_2 = (e) => {
		e.preventDefault();
		setFormSubmitModal(true);
		// console.log(modalData2);
		const data = new FormData();

		data.append("schoolname", modalData2.schoolname);
		data.append("code", modalData2.code);
		data.append("description", modalData2.description);

		axios
			.post(`${url}/addNewSchool`, modalData2)
			.then((res) => {
				setFormSubmitModal(false);
				notifySuccess(res.data.success);
				getUser();
				toggleOtherModal();
			})
			.catch((err) => {
				setFormSubmitModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	useEffect(() => {
		const getUser = () => {
			setIsloading(true);
			axios
				.get(`${url}/GeTables`)
				.then((res) => {
					// console.log(res.data.data);
					setData(res.data.data);
					// setDisplayData(res.data.data);
					setTotalWksht(res.data.data.length);
					setIsloading(false);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getUser();
	}, [url]);

	return (
		<div className="b2smain_container">
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<>
					{/* SHOW TOTAL WORKSHEET, COMPLETED AND UNCOMPLETED */}
					<div className="container">
						<div className="row mb-2">
							<div className="col-sm-6">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											// setDisplayData(data);
										}}
									>
										Total number of schools:{" "}
										<Badge
											color="dark"
											style={{
												fontSize: "1rem",
											}}
										>
											{totalWksht}
										</Badge>
									</Button>
								</div>
							</div>

							{/* BUTTON FOR LAUNCHING MODAL FOR ADDING NEW ACTIVITIES */}
							<div className="col-sm-6">
								<div>
									<Button
										size="lg"
										style={{
											backgroundColor: "blue",
											hover: "#0275d8",
										}}
										className="p-3"
										onClick={() => {
											toggleOtherModal();
										}}
									>
										Add New School
									</Button>
								</div>
							</div>
						</div>
					</div>

					{/* </div> */}

					<MaterialTable
						icons={tableIcons}
						options={{
							exportButton: true,
							exportAllData: true,
						}}
						columns={[
							{ title: "ID", field: "discountid" },
							{ title: "School Name", field: "client" },
							{
								title: "Schools Promo Code",
								field: "discountcode",
								type: "numeric",
							},
							{ title: "Description", field: "description" },
							{ title: "End Date", field: "enddate" },
						]}
						data={data.map((item) => ({ ...item }))}
						title="List of Schools"
					/>
					{/* MODAL FOR ADDING NEW ACTIVITIES */}
					<div>
						<Modal
							isOpen={otherModal}
							toggle={toggleOtherModal}
							backdrop={"static"}
							keyboard={false}
						>
							<ModalHeader toggle={toggleOtherModal}>
								Add New School
							</ModalHeader>
							<ModalBody>
								<Form
									onSubmit={handleSubmit_2}
									method="POST"
									encType="multipart/form-data"
								>
									<FormGroup row>
										<Label for="worksheetname" sm={3}>
											School Name
										</Label>
										<Col sm={9}>
											<Input
												type="text"
												name="schoolname"
												id="schoolname"
												required
												defaultValue={
													modalData2.schoolname
												}
												onBlur={handleChange_2}
											/>
										</Col>
									</FormGroup>

									<FormGroup row>
										<Label for="worksheetcode" sm={3}>
											Code
										</Label>
										<Col sm={9}>
											<Input
												type="text"
												name="code"
												id="code"
												required
												defaultValue={modalData2.code}
												onBlur={handleChange_2}
											/>
											<p style={{ color: "red" }}>
												*must be a 6 digits code
											</p>
										</Col>
									</FormGroup>

									<FormGroup row>
										<Label for="description" sm={3}>
											Description
										</Label>
										<Col sm={9}>
											<Input
												type="textarea"
												name="description"
												id="description"
												required
												defaultValue={
													modalData2.description
												}
												onBlur={handleChange_2}
											/>
											<p style={{ color: "red" }}>
												*name of school 3 months of
												premium. eg (ABC 6 months of
												premium)
											</p>
										</Col>
									</FormGroup>

									<FormGroup check row>
										<Col
											sm={{ size: 5, offset: 4 }}
											className="text-center"
										>
											<Button
												// color="primary"
												type="submit"
												style={{
													backgroundColor: "blue",
												}}
											>
												Submit
											</Button>
										</Col>
									</FormGroup>
								</Form>
							</ModalBody>
							<ModalFooter>
								<Button
									color="secondary"
									onClick={toggleOtherModal}
								>
									Cancel
								</Button>
							</ModalFooter>
						</Modal>
					</div>
				</>
			)}

			{/* FORM SUBMIT LOADING MODAL */}
			<div>
				<LoadingModal
					isOpen={formSubmitModal}
					toggle={toggleFormSubmitModal}
				/>
			</div>
		</div>
	);
};

export default B2SDashboard;
