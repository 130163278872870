import React, { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import {
	Button,
	Badge,
	// Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant2";
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
toast.configure();

const SignUpSummary = () => {
	//base url
	var url = config.url.API_URL;

	const [isloading, setIsloading] = useState(false);
	const [alldata, setAllData] = useState([]);
	const [data, setData] = useState([]);
	// const [tableData, setableData] = useState(data)
	const [displayData, setDisplayData] = useState([]);
	const [totalWksht, setTotalWksht] = useState(0);
	const [schools, setschools] = useState([]);

	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Delete: forwardRef((props, ref) => (
			<DeleteOutline {...props} ref={ref} />
		)),
		DetailPanel: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		FirstPage: forwardRef((props, ref) => (
			<FirstPage {...props} ref={ref} />
		)),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		NextPage: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		PreviousPage: forwardRef((props, ref) => (
			<ChevronLeft {...props} ref={ref} />
		)),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		SortArrow: forwardRef((props, ref) => (
			<ArrowDownward {...props} ref={ref} />
		)),
		ThirdStateCheck: forwardRef((props, ref) => (
			<Remove {...props} ref={ref} />
		)),
		ViewColumn: forwardRef((props, ref) => (
			<ViewColumn {...props} ref={ref} />
		)),
	};

	useEffect(() => {
		const getUser = () => {
			setIsloading(true);
			axios
				.get(`${url}/GetSignups`)
				.then((res) => {
					setData(res.data.data);
					setAllData(res.data.data);
					setDisplayData(res.data.data);
					setTotalWksht(res.data.data.length);
					setIsloading(false);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		const getSchool = () => {
			axios
				.get(`${url}/GetSchools`)
				.then((res) => {
					setschools(res.data.data);
				})
				.catch((err) => {
					console.log(err);
				});
		};

		getSchool();
		getUser();
	}, [url]);

	function handleChange(event) {
		const school = event.target.value;
		if (school === "All") {
			setDisplayData(alldata);
			setTotalWksht(alldata.length);
		} else {
			const filteredData = alldata.filter(
				(item) => item.school === school
			);
			setDisplayData(filteredData);
			setTotalWksht(filteredData.length);
		}
	}

	return (
		<div className="b2smain_container">
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<>
					{/* SHOW TOTAL NUMBER OF SIGNUPS*/}
					<div className="container">
						<div className="row mb-2">
							<div className="col-sm-6">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(data);
										}}
									>
										Total number of signups:{" "}
										<Badge
											color="dark"
											style={{
												fontSize: "1rem",
											}}
										>
											{totalWksht}
										</Badge>
									</Button>
								</div>
							</div>
							<div className="col-sm-6 pt-3">
								<div>
									<label htmlFor="schools">
										Filter by school:{" "}
									</label>
									<select
										name="schools"
										id="schools"
										className="p-2 ml-2"
										onChange={handleChange}
									>
										<option key="1">All</option>
										{schools.map((item) => {
											return (
												<option key={item.discountid}>
													{item.school}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
					</div>

					{/* DATA TABLES THAT DISPLAYS ALL CURRENT ACTIVITIES */}
					<MaterialTable
						icons={tableIcons}
						options={{
							exportButton: true,
							exportAllData: true,
						}}
						columns={[
							{ title: "ID", field: "discountactivityid" },
							{ title: "Name", field: "name" },
							{
								title: "Schools Promo Code",
								field: "discountcode",
								type: "numeric",
							},
							{ title: "School Name", field: "school" },
							{ title: "Created Date", field: "created date" },
						]}
						data={displayData.map((item) => ({ ...item }))}
						title="List of Schools"
					/>
				</>
			)}
		</div>
	);
};

export default SignUpSummary;
