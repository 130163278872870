import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { config } from "../../../Constant";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";

const Register = () => {
	const { Column, HeaderCell, Cell } = Table;
	const [isloading, setIsloading] = useState(false);
	const [userData, setUserData] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [editModalData, setEditModalData] = useState({});
	const [addModal, setAddModal] = useState(false);
	const [addModalData, setAddModalData] = useState({ role: "games" });
	const [formSubmitModal, setFormSubmitModal] = useState(false);
	const [reload, setReload] = useState(false);

	// Modal Toggle Functions
	const toggleEditModal = () => setEditModal(!editModal);
	const toggleAddModal = () => setAddModal(!addModal);
	const toggleFormSubmitModal = () => setFormSubmitModal(!formSubmitModal);

	const notifySuccess = () =>
		toast.success("Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});

	const getLoginLog = () => {
		setIsloading(true);
		axios
			.get(`${config.url.API_URL}/getUsers`)
			.then((res) => {
				setUserData(res.data.data);
				setIsloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// HANDLE ONCHANGE ACTION FOR EDITTING CURRENT USER
	const handleChangeEditModal = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setEditModalData({ ...editModalData, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FORM SUBMIT FOR EDITTING CURRENT USER
	const handleSubmitEditModal = (e) => {
		e.preventDefault();
		setFormSubmitModal(true);
		// console.log(modalData);
		const data = new FormData();
		data.append("name", editModalData.name);
		data.append("role", editModalData.role);

		axios
			.post(
				`${config.url.API_URL}/updateUsers`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setEditModal(false);
				setFormSubmitModal(false);
				// console.log(res);
				notifySuccess();
				setReload(!reload);
			})
			.catch((err) => {
				setFormSubmitModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					// console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	// HANDLE ONCHANGE ACTION FOR EDITTING CURRENT USER
	const handleChangeAddModal = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setAddModalData({ ...addModalData, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FORM SUBMIT FOR EDITTING CURRENT USER
	const handleSubmitAddModal = (e) => {
		e.preventDefault();
		setFormSubmitModal(true);
		// console.log(modalData);
		const data = new FormData();
		data.append("name", addModalData.name);
		data.append("role", addModalData.role);

		axios
			.post(
				`${config.url.API_URL}/addUsers`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setAddModal(false);
				setFormSubmitModal(false);
				// console.log(res);
				notifySuccess();
				setReload(!reload);
			})
			.catch((err) => {
				setFormSubmitModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					// console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	useEffect(() => {
		getLoginLog();
	}, [reload]);
	return (
		<div className="main_container" style={{}}>
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<div className="container">
					{/* ROW TO ADD USER */}
					<div className="row mb-2 text-center">
						<div className="col-12">
							<div>
								<Button
									size="lg"
									color="primary"
									onClick={() => {
										toggleAddModal();
									}}
								>
									Add User
								</Button>
							</div>
						</div>
					</div>

					{/* THE USER TABLE */}
					<div
						className="row text-center"
						style={{
							height: "100vh",
						}}
					>
						<div className="col-8 mx-auto my-auto">
							<Table data={userData} height={500}>
								<Column width={200} sortable fixed resizable>
									<HeaderCell>Name</HeaderCell>
									<Cell dataKey="name" />
								</Column>

								<Column width={200} sortable fixed resizable>
									<HeaderCell>Role</HeaderCell>
									<Cell dataKey="role" />
								</Column>

								<Column width={200} sortable fixed resizable>
									<HeaderCell>Code</HeaderCell>
									<Cell dataKey="code" />
								</Column>

								<Column width={70} fixed={"right"} resizable>
									<HeaderCell>Action</HeaderCell>
									<Cell>
										{(rowData) => {
											return (
												<>
													<Button
														size="md"
														color="primary"
														onClick={() => {
															setEditModalData(
																rowData
															);
															toggleEditModal();
														}}
													>
														Edit
													</Button>
												</>
											);
										}}
									</Cell>
								</Column>
							</Table>
						</div>
					</div>

					{/* MODAL FOR EDITTING USER */}
					<div>
						<Modal
							isOpen={editModal}
							toggle={toggleEditModal}
							className=""
							style={{
								fontSize: "14px",
							}}
							scrollable={true}
						>
							<ModalHeader toggle={toggleEditModal}>
								Edit StoryBook
							</ModalHeader>
							<ModalBody>
								<Form
									onSubmit={handleSubmitEditModal}
									method="POST"
									encType="multipart/form-data"
								>
									<FormGroup row>
										<Label for="name" sm={3}>
											Name
										</Label>
										<Col sm={9}>
											<Input
												type="text"
												name="name"
												id="name"
												defaultValue={
													editModalData.name
												}
												onBlur={handleChangeEditModal}
											/>
										</Col>
									</FormGroup>

									<FormGroup row>
										<Label for="description" sm={3}>
											Role
										</Label>
										<Col sm={9}>
											<Input
												type="select"
												name="role"
												id="role"
												defaultValue={
													editModalData.role
												}
												onBlur={handleChangeEditModal}
											>
												<option value="games">
													Games
												</option>
												<option value="marketing">
													Marketing
												</option>
											</Input>
										</Col>
									</FormGroup>

									<FormGroup check row>
										<Col sm={{ size: 5, offset: 4 }}>
											<Button
												size="md"
												color="primary"
												type="submit"
											>
												Submit
											</Button>
										</Col>
									</FormGroup>
								</Form>
							</ModalBody>
							<ModalFooter>
								<Button
									color="secondary"
									onClick={toggleEditModal}
									size="md"
								>
									Cancel
								</Button>
							</ModalFooter>
						</Modal>
					</div>

					{/* MODAL FOR ADDING NEW USER */}
					<div>
						<Modal
							isOpen={addModal}
							toggle={toggleAddModal}
							className=""
							style={{
								fontSize: "14px",
							}}
							scrollable={true}
						>
							<ModalHeader toggle={toggleAddModal}>
								Add User
							</ModalHeader>
							<ModalBody>
								<Form
									onSubmit={handleSubmitAddModal}
									method="POST"
									encType="multipart/form-data"
								>
									<FormGroup row>
										<Label for="name" sm={3}>
											Name
										</Label>
										<Col sm={9}>
											<Input
												type="text"
												name="name"
												id="name"
												defaultValue={addModalData.name}
												onBlur={handleChangeAddModal}
											/>
										</Col>
									</FormGroup>

									<FormGroup row>
										<Label for="role" sm={3}>
											Role
										</Label>
										<Col sm={9}>
											<Input
												type="select"
												name="role"
												id="role"
												defaultValue={addModalData.role}
												onBlur={handleChangeAddModal}
											>
												<option value="games">
													Games
												</option>
												<option value="marketing">
													Marketing
												</option>
											</Input>
										</Col>
									</FormGroup>

									<FormGroup check row>
										<Col sm={{ size: 5, offset: 4 }}>
											<Button
												size="md"
												color="primary"
												type="submit"
											>
												Submit
											</Button>
										</Col>
									</FormGroup>
								</Form>
							</ModalBody>
							<ModalFooter>
								<Button
									color="secondary"
									onClick={toggleAddModal}
									size="md"
								>
									Cancel
								</Button>
							</ModalFooter>
						</Modal>
					</div>
				</div>
			)}

			<div>
				<LoadingModal
					isOpen={formSubmitModal}
					toggle={toggleFormSubmitModal}
				/>
			</div>
		</div>
	);
};

export default Register;
