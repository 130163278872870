import React from "react";
import axios from "axios";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";
import JSZip from "jszip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant";
import LoadingModal from "../../../layout/LoadingModal/LoadingModal";

const InteractiveFolderUploadModal = (props) => {
	const [interFolderModalData, setInterFolderModalData] = React.useState({});
	const [loadingModal, setLoadingModal] = React.useState(false);
	const toggleLoadingModal = () => setLoadingModal(!loadingModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});

	// HANDLE FORM SUBMIT FOR INTERACTIVE FOLDER UPLOAD
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoadingModal(true);
		console.log(interFolderModalData);
		const data = new FormData();
		// if (interFolderModalData.foldername) {
		// 	data.append("foldername", interFolderModalData.foldername);
		// }
		if (interFolderModalData.folder) {
			var zip = new JSZip();
			for (const key of Object.keys(interFolderModalData.folder)) {
				let file = interFolderModalData.folder[key];
				zip.file(file.webkitRelativePath, file);
			}
		}

		// console.log(zip);

		zip.generateAsync({ type: "blob" }).then(function (content) {
			data.append("folderzip", content);
			console.log(content);
			axios
				.post(
					`${config.url.API_URL}/interActivityFolderUpload`,

					data,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				)
				.then((res) => {
					setLoadingModal(false);
					console.log(res);
					notifySuccess();
					// window.location.href = "/admin/dashboard";
				})
				.catch((err) => {
					setLoadingModal(false);
					if (err.response) {
						if (err.response.data.message) {
							notifyFailure(err.response.data.message);
						} else if (err.response.data.error) {
							notifyFailure(err.response.data.error);
						} else {
							notifyFailure("Opps an Error Occurred");
						}
					} else {
						console.log(err.response);
						notifyFailure("Opps an Error Occurred");
					}
				});
		});

		// console.log(Object.keys(bulkUpModalData.files));
		// console.log(data);
	};

	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR INTERACTIVE FOLDER UPLOAD FORM
	const handleFileChange = (e) => {
		console.log(e.target.files);
		setInterFolderModalData({
			...interFolderModalData,
			[e.target.name]: e.target.files,
		});
	};

	return (
		<div>
			<Modal
				{...props}
				className=""
				style={{
					fontSize: "14px",
				}}
			>
				<ModalHeader toggle={props.toggle}>
					Interactive Folder Upload
				</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={handleSubmit}
						method="POST"
						encType="multipart/form-data"
					>
						<FormGroup row>
							<Label for="folder" sm={3}>
								Upload Interactive Folder
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="folder"
									id="folder"
									required
									multiple
									directory=""
									webkitdirectory=""
									moxdirectory=""
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Interactive Folder (note: if
									Interactive already exists this will
									override it)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup check row>
							<Col sm={{ size: 5, offset: 4 }}>
								<Button color="primary" type="submit" size="md">
									Submit
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={props.toggle} size="md">
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{/* Loading Modal Pop Up */}
			<LoadingModal isOpen={loadingModal} toggle={toggleLoadingModal} />
		</div>
	);
};

export default InteractiveFolderUploadModal;
