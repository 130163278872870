const prod = {
	url: {
		// API_URL: "https://nimdeewksht.sandbox.9ijakids.com/back2school/api/api.php",
		API_URL: "https://nimdee.com/back2school/api/api.php",
	},
};
const dev = {
	url: {
		// API_URL: "https://afternoon-ridge-35420.herokuapp.com/https://nimdeewksht.sandbox.9ijakids.com/back2school/api/api.php",
		API_URL:
			"https://afternoon-ridge-35420.herokuapp.com/https://nimdee.com/back2school/api/api.php",
	},
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
