import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import "./StoryBookDashboard.css";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../Constant";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";
import AddStoryModal from "./AddStoryModal/AddStoryModal";
import BulkStoryUploadModal from "./BulkStoryUploadModal/BulkStoryUploadModal";
import CsvStoryModal from "./CsvStoryModal/CsvStoryModal";

const StoryBookDashboard = () => {
	const { Column, HeaderCell, Cell, Pagination } = Table;
	const [data, setData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [inCompleteStory, setInCompleteStory] = useState(0);
	const [inCompleteStoryData, setInCompleteStoryData] = useState([]);
	const [completeStory, setCompleteStory] = useState(0);
	const [completeStoryData, setCompleteStoryData] = useState([]);
	const [totalStory, setTotalStory] = useState(0);
	const [sortColumn, setSortColumn] = useState("activityid");
	const [sortType, setSortType] = useState("asc");
	const [displayLength, setDisplayLength] = useState(10);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [newStoryModal, setNewStoryModal] = useState(false);
	const [bulkUploadModal, setBulkUploadModal] = useState(false);
	const [csvUploadModal, setCsvUploadModal] = useState(false);
	const [modalData, setModalData] = useState({});
	const [isloading, setIsloading] = useState(false);
	const [formSubmitModal, setFormSubmitModal] = useState(false);

	// Modal Toggle Functions
	const toggle = () => setModal(!modal);
	const toggleFormSubmitModal = () => setFormSubmitModal(!formSubmitModal);
	const toggleNewStoryModal = () => setNewStoryModal(!newStoryModal);
	const toggleBulkUploadModal = () => setBulkUploadModal(!bulkUploadModal);
	const toggleCsvUploadModal = () => setCsvUploadModal(!csvUploadModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});

	const getStoryBook = () => {
		setIsloading(true);
		axios
			.get(`${config.url.API_URL}/getStoryBook`)
			.then((res) => {
				console.log(res);
				setData(res.data.data);
				setDisplayData(res.data.data);
				const completeArray = res.data.data.filter(
					(datalet) =>
						datalet.name !== null &&
						datalet.name !== "null" &&
						datalet.name !== "NULL" &&
						datalet.name !== "" &&
						datalet.description !== null &&
						datalet.description !== "null" &&
						datalet.description !== "NULL" &&
						datalet.description !== "" &&
						datalet.image_url !== null &&
						datalet.image_url !== "null" &&
						datalet.image_url !== "NULL" &&
						datalet.image_url !== "" &&
						datalet.pdf_url !== null &&
						datalet.pdf_url !== "null" &&
						datalet.pdf_url !== "NULL" &&
						datalet.pdf_url !== ""
				);
				let difference = res.data.data.filter(
					(x) => !completeArray.includes(x)
				);
				setCompleteStoryData(completeArray);
				setInCompleteStoryData(difference);

				setInCompleteStory(res.data.data.length - completeArray.length);
				setTotalStory(res.data.data.length);
				setCompleteStory(completeArray.length);

				setIsloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const renderStatus = (datalet) => {
		if (
			datalet.name !== null &&
			datalet.name !== "null" &&
			datalet.name !== "NULL" &&
			datalet.name !== "" &&
			datalet.description !== null &&
			datalet.description !== "null" &&
			datalet.description !== "NULL" &&
			datalet.description !== "" &&
			datalet.image_url !== null &&
			datalet.image_url !== "null" &&
			datalet.image_url !== "NULL" &&
			datalet.image_url !== "" &&
			datalet.pdf_url !== null &&
			datalet.pdf_url !== "null" &&
			datalet.pdf_url !== "NULL" &&
			datalet.pdf_url !== ""
		) {
			return <p>Complete</p>;
		} else {
			return <p>Incomplete</p>;
		}
	};

	const handleSortColumn = (sortColumn, sortType) => {
		setLoading(true);
		setTimeout(() => {
			setSortColumn(sortColumn);
			setSortType(sortType);
			setLoading(false);
		}, 500);
	};

	const handleChangePage = (datakey) => {
		setPage(datakey);
	};

	const handleChangeLength = (datakey) => {
		setPage(1);
		setDisplayLength(datakey);
	};
	const getData = () => {
		// const { data, sortColumn, sortType } = this.state;
		const data = displayData;

		if (sortColumn && sortType) {
			const filterdata = data.filter((v, i) => {
				const start = displayLength * (page - 1);
				const end = start + displayLength;
				return i >= start && i < end;
			});

			return filterdata.sort((a, b) => {
				let x = a[sortColumn];
				let y = b[sortColumn];
				if (typeof x === "string") {
					x = x.charCodeAt();
				}
				if (typeof y === "string") {
					y = y.charCodeAt();
				}
				if (sortType === "asc") {
					return x - y;
				} else {
					return y - x;
				}
			});
		}
	};

	// HANDLE ONCHANGE ACTION FOR EDITTING CURRENT STOYR BOOK
	const handleChange = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setModalData({ ...modalData, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR EDITTING CURRENT STORY BOOK
	const handleFileChange = (e) => {
		console.log(e.target.files);
		setModalData({ ...modalData, [e.target.name]: e.target.files[0] });
	};

	// HANDLE FORM SUBMIT FOR EDITTING CURRENT STORY BOOK
	const handleSubmit = (e) => {
		e.preventDefault();
		setFormSubmitModal(true);
		// console.log(modalData);
		const data = new FormData();
		data.append("pdf", modalData.pdf);
		data.append("image", modalData.image);
		data.append("bookid", modalData.bookid);
		data.append("name", modalData.name);
		data.append("image_url", modalData.image_url);
		data.append("pdf_url", modalData.pdf_url);
		data.append("description", modalData.description);
		axios
			.post(
				`${config.url.API_URL}/updateStoryBook`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setFormSubmitModal(false);
				console.log(res);
				notifySuccess();
				window.location.href = "/admin/storybooks";
			})
			.catch((err) => {
				setFormSubmitModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	useEffect(() => {
		getStoryBook();
	}, []);
	return (
		<div className="main_container" style={{}}>
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<>
					<div className="container" style={{}}>
						{/* <h3>Worksheet Dashboard</h3> */}

						{/* BUTTONS ROW */}
						<div className="row mb-2">
							{/* BUTTON FOR LAUNCHING MODAL FOR ADDING NEW STORYBOOK */}
							<div className="col-sm-4">
								<div>
									<Button
										size="lg"
										color="primary"
										onClick={() => {
											toggleNewStoryModal();
										}}
									>
										Add Activity
									</Button>
								</div>
							</div>

							{/* BUTTON FOR LAUNCHING MODAL FOR ADDING BULK UPLOAD OF FILES */}
							<div className="col-sm-4">
								<div>
									<Button
										size="lg"
										color="primary"
										onClick={() => {
											toggleBulkUploadModal();
										}}
									>
										Bulk Upload Files
									</Button>
								</div>
							</div>

							{/* BUTTON FOR UPLOADING EXCEL SHEETSHEET/CSV FILES WITH DB DATA */}
							<div className="col-sm-4">
								<Button
									size="lg"
									color="primary"
									onClick={() => {
										toggleCsvUploadModal();
									}}
								>
									CSV File Upload
								</Button>
							</div>
						</div>

						{/* SHOW TOTAL STORY BOOK, COMPLETED AND UNCOMPLETED */}
						<div className="row mb-2">
							<div className="col-sm-4">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(data);
										}}
									>
										Available Stories:{" "}
										<span className="stat-spans" style={{}}>
											{totalStory}
										</span>
									</Button>
								</div>
							</div>
							<div className="col-sm-4">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(completeStoryData);
										}}
									>
										Completed Stories:{" "}
										<span className="stat-spans" style={{}}>
											{completeStory}
										</span>
									</Button>
								</div>
							</div>

							<div className="col-sm-4">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(inCompleteStoryData);
										}}
									>
										Incomplete Stories:{" "}
										<span className="stat-spans" style={{}}>
											{inCompleteStory}
										</span>
									</Button>
								</div>
							</div>
						</div>

						{/* DATA TABLES THAT DISPLAYS ALL CURRENT STORYBOOK */}
						<Table
							wordWrap
							data={getData()}
							sortColumn={sortColumn}
							sortType={sortType}
							onSortColumn={handleSortColumn}
							loading={loading}
							height={500}
							// autoHeight
						>
							<Column width={50} sortable fixed resizable>
								<HeaderCell>ID</HeaderCell>
								<Cell dataKey="bookid" />
							</Column>

							<Column width={100} sortable fixed resizable>
								<HeaderCell>Name</HeaderCell>
								<Cell dataKey="name" />
							</Column>

							<Column width={250} sortable fixed resizable>
								<HeaderCell>Description</HeaderCell>
								<Cell dataKey="description" />
							</Column>

							<Column width={180} sortable fixed resizable>
								<HeaderCell>Image_url</HeaderCell>
								<Cell dataKey="image_url" />
							</Column>

							<Column width={180} sortable fixed resizable>
								<HeaderCell>Pdf_url</HeaderCell>
								<Cell dataKey="pdf_url" />
							</Column>

							<Column width={100} sortable fixed resizable>
								<HeaderCell>Create Date</HeaderCell>
								<Cell dataKey="create_date" />
							</Column>

							<Column width={70} fixed={"right"} resizable>
								<HeaderCell>Action</HeaderCell>
								<Cell>
									{(rowData) => {
										return (
											<>
												<Button
													size="md"
													color="primary"
													onClick={() => {
														setModalData(rowData);

														toggle();
													}}
												>
													Edit
												</Button>
											</>
										);
									}}
								</Cell>
							</Column>

							<Column width={100} fixed={"right"} resizable>
								<HeaderCell>Status</HeaderCell>
								<Cell>
									{(rowData) => {
										return renderStatus(rowData);
									}}
								</Cell>
							</Column>
						</Table>

						<Pagination
							lengthMenu={[
								{
									value: 10,
									label: 10,
								},
								{
									value: 20,
									label: 20,
								},
							]}
							activePage={page}
							displayLength={displayLength}
							total={displayData.length}
							onChangePage={handleChangePage}
							onChangeLength={handleChangeLength}
						/>

						{/* MODAL FOR EDITTING CURRENT STORYBOOK */}
						<div>
							<Modal
								isOpen={modal}
								toggle={toggle}
								className=""
								style={{
									fontSize: "14px",
								}}
								scrollable={true}
							>
								<ModalHeader toggle={toggle}>
									Edit StoryBook
								</ModalHeader>
								<ModalBody>
									<Form
										onSubmit={handleSubmit}
										method="POST"
										encType="multipart/form-data"
									>
										<FormGroup row>
											<Label for="name" sm={3}>
												Name
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="name"
													id="name"
													defaultValue={
														modalData.name
													}
													onBlur={handleChange}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="description" sm={3}>
												Description
											</Label>
											<Col sm={9}>
												<Input
													type="textarea"
													name="description"
													id="description"
													defaultValue={
														modalData.description
													}
													onBlur={handleChange}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="image_url" sm={3}>
												Current Image
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="image_url"
													id="image_url"
													readonly
													value={modalData.image_url}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="image" sm={3}>
												Upload Image
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="image"
													id="image"
													accept=".png"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Image (note:
													if Image already exists this
													will override it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="pdf_url" sm={3}>
												Current Story Pdf
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="pdf_url"
													id="pdf_url"
													readonly
													value={modalData.pdf_url}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="pdf" sm={3}>
												Upload Story Pdf
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="pdf"
													id="pdf"
													accept=".pdf"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Story Pdf
													(note: if Story Pdf already
													exists this will override
													it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup check row>
											<Col sm={{ size: 5, offset: 4 }}>
												<Button
													size="md"
													color="primary"
													type="submit"
												>
													Submit
												</Button>
											</Col>
										</FormGroup>
									</Form>
								</ModalBody>
								<ModalFooter>
									<Button
										color="secondary"
										onClick={toggle}
										size="md"
									>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>
						</div>

						{/* MODAL FOR ADDING NEW STORYBOOK */}
						<div>
							<AddStoryModal
								isOpen={newStoryModal}
								toggle={toggleNewStoryModal}
							/>
						</div>

						<div>
							<BulkStoryUploadModal
								isOpen={bulkUploadModal}
								toggle={toggleBulkUploadModal}
							/>
						</div>

						<div>
							<CsvStoryModal
								isOpen={csvUploadModal}
								toggle={toggleCsvUploadModal}
							/>
						</div>
					</div>
				</>
			)}

			<div>
				<LoadingModal
					isOpen={formSubmitModal}
					toggle={toggleFormSubmitModal}
				/>
			</div>
		</div>
	);
};

export default StoryBookDashboard;
