import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import classes from "./LandingPage.module.css";
import axios from "axios";
import { config } from "../../../Constant";
import { config2 } from "../../../Constant3";

import { Button } from "reactstrap";

const LandingPage = () => {
	const adminUser = JSON.parse(sessionStorage.getItem("nimdee-admin"));
	const [parentAccountsNo, setParentAccountsNo] = useState(0);
	const [childAccountsNo, setChildAccountsNo] = useState(0); const [WorkBooksCount, setWorkBooksCount] = useState(0);
	const [WorkSheetsCount, setWorkSheetsCount] = useState(0);
	const [currentDate, setCurrentDate] = useState("");

	const history = useHistory();

	const logUserOut = () => {
		sessionStorage.removeItem("nimdee-admin");
		history.push("/login");
	};

	const getNumberParentAccounts = () => {
		axios
			.get(`${config.url.API_URL}/getNumberParentAccounts`)
			.then((res) => {
				console.log(res);
				setParentAccountsNo(Number(res.data.count));
			});
	};

	const getNumberChildAccounts = () => {
		axios
			.get(`${config.url.API_URL}/getNumberChildAccounts`)
			.then((res) => {
				console.log(res);
				setChildAccountsNo(Number(res.data.count));
			});
	};

	const getNumberOfWorkBooksDownloaded = () => {
		axios
			.get(`${config2.url.API_URL}/WorkBook/workBook.php/GetWorkBookLog?page_number=${1}&page_size=${100}`)
			.then((res) => {
				console.log(res.data.data);
				setWorkBooksCount(res.data.total_count);
			})
	};

	const getNumberOfWorkSheetsDownloaded = () => {
		axios
			.get(`${config2.url.API_URL}/Worksheet/worksheet.php/GetWorkSheetLog`)
			.then((res) => {
				setWorkSheetsCount(res.data.total_count);
			})
	};

	const getCurrentDateTime = () => {
		let today = new Date();

		setCurrentDate(today.toDateString());
	};

	useEffect(() => {
		getNumberParentAccounts();
		getNumberChildAccounts();
		getCurrentDateTime(); getNumberOfWorkSheetsDownloaded();
		getNumberOfWorkBooksDownloaded();
	}, []);
	return (
		<section className={classes.Home}>
			<select>
				<option>{currentDate}</option>
			</select>
			<div>
				<h1>Welcome {adminUser.accountName}</h1>
				<Button color="primary" size="lg" outline style={{}}>
					Parent SignUps:{" "}
					<span className="stat-spans" style={{}}>
						{parentAccountsNo}
					</span>
				</Button>
				<Button color="primary" size="lg" outline style={{}}>
					Child SignUps:{" "}
					<span className="stat-spans" style={{}}>
						{childAccountsNo}
					</span>
				</Button>

				<Button color="primary" size="lg" outline style={{}}>
					Workbook Downloads:
					<span className="stat-spans" style={{}}>
						{WorkBooksCount}
					</span>
				</Button>
				<Button color="primary" size="lg" outline style={{}}>
					Worksheet Downloads:
					<span className="stat-spans" style={{}}>
						{WorkSheetsCount}
					</span>
				</Button>
				<button onClick={logUserOut} className={classes.Button}>
					log out
				</button>
			</div>
		</section>
	);
};

export default LandingPage;
