import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import "./WorksheetDashboard.css";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../Constant";
import AddActivityModal from "./AddActivityModal/AddActivityModal";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";
import BulkFileUploadModal from "./BulkFileUploadModal/BulkFileUploadModal";
import InteractiveFolderUploadModal from "./InteractiveFolderUploadModal/InteractiveFolderUploadModal";
import CSVFileUploadModal from "./CSVFileUploadModal/CSVFileUploadModal";
toast.configure();

const WorksheetDashboard = () => {
	const { Column, HeaderCell, Cell, Pagination } = Table;
	const [data, setData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [sortColumn, setSortColumn] = useState("activityid");
	const [sortType, setSortType] = useState("asc");
	const [displayLength, setDisplayLength] = useState(10);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [
		completeWksheetNoInteractiveData,
		setCompleteWksheetNoInteractiveData,
	] = useState([]);
	const [completeWksheetData, setCompleteWksheetData] = useState([]);
	const [InCompleteWksheetData, setInCompleteWksheetData] = useState([]);
	const [subarearef, setSubAreaRef] = useState([]);
	const [isloading, setIsloading] = useState(false);
	const [levelcode, setLevelCode] = useState([]);
	const [modalData, setModalData] = useState({});
	const [formSubmitModal, setFormSubmitModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [newActivityModal, setNewActivityModal] = useState(false);
	const [csvModal, setcsvModal] = useState(false);
	const [bulkUpModal, setBulkUpModal] = useState(false);
	const [interFolderModal, setInterFolderModal] = useState(false);
	const [totalWksht, setTotalWksht] = useState(0);
	const [
		completeWithoutInteractiveWksht,
		setCompleteWithoutInteractiveWksht,
	] = useState(0);
	const [completeWithInteractiveWksht, setCompleteWithInteractiveWksht] =
		useState(0);

	const toggle = () => setModal(!modal);
	const toggleNewActivityModal = () => setNewActivityModal(!newActivityModal);
	const toggleBulkUpModal = () => setBulkUpModal(!bulkUpModal);
	const toggleFormSubmitModal = () => setFormSubmitModal(!formSubmitModal);
	const toggleInterFolderModal = () => setInterFolderModal(!interFolderModal);
	const toggleCsvModal = () => setcsvModal(!csvModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});

	const getActivity = () => {
		setIsloading(true);
		axios
			.get(`${config.url.API_URL}/getActivity`)
			.then((res) => {
				console.log(res);
				setData(res.data.data);
				setDisplayData(res.data.data);
				const completeArray = res.data.data.filter(
					(datalet) =>
						datalet.featuredimg !== null &&
						datalet.featuredimg !== "null" &&
						datalet.featuredimg !== "NULL" &&
						datalet.featuredimg !== "" &&
						datalet.title !== null &&
						datalet.title !== "null" &&
						datalet.title !== "NULL" &&
						datalet.title !== "" &&
						datalet.description !== null &&
						datalet.description !== "null" &&
						datalet.description !== "NULL" &&
						datalet.description !== "" &&
						datalet.thumbnail !== null &&
						datalet.thumbnail !== "null" &&
						datalet.thumbnail !== "NULL" &&
						datalet.thumbnail !== "" &&
						datalet.offlinepdfurl !== null &&
						datalet.offlinepdfurl !== "null" &&
						datalet.offlinepdfurl !== "NULL" &&
						datalet.offlinepdfurl !== "" &&
						datalet.answersheeturl !== null &&
						datalet.answersheeturl !== "null" &&
						datalet.answersheeturl !== "NULL" &&
						datalet.answersheeturl !== ""
				);
				const completePlusInteractiveArray = res.data.data.filter(
					(datalet) =>
						datalet.featuredimg !== null &&
						datalet.featuredimg !== "null" &&
						datalet.featuredimg !== "NULL" &&
						datalet.featuredimg !== "" &&
						datalet.title !== null &&
						datalet.title !== "null" &&
						datalet.title !== "NULL" &&
						datalet.title !== "" &&
						datalet.description !== null &&
						datalet.description !== "null" &&
						datalet.description !== "NULL" &&
						datalet.description !== "" &&
						datalet.thumbnail !== null &&
						datalet.thumbnail !== "null" &&
						datalet.thumbnail !== "NULL" &&
						datalet.thumbnail !== "" &&
						datalet.offlinepdfurl !== null &&
						datalet.offlinepdfurl !== "null" &&
						datalet.offlinepdfurl !== "NULL" &&
						datalet.offlinepdfurl !== "" &&
						datalet.onlinepdfurl !== null &&
						datalet.onlinepdfurl !== "null" &&
						datalet.onlinepdfurl !== "NULL" &&
						datalet.onlinepdfurl !== "" &&
						datalet.answersheeturl !== null &&
						datalet.answersheeturl !== "null" &&
						datalet.answersheeturl !== "NULL" &&
						datalet.answersheeturl !== ""
				);
				setCompleteWithInteractiveWksht(
					completePlusInteractiveArray.length
				);
				setCompleteWksheetData(completePlusInteractiveArray);
				setCompleteWksheetNoInteractiveData(completeArray);

				// https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
				let difference = res.data.data.filter(
					(x) => !completeArray.includes(x)
				);
				setInCompleteWksheetData(difference);
				setTotalWksht(res.data.data.length);
				setCompleteWithoutInteractiveWksht(completeArray.length);

				setIsloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getSubarearef = () => {
		axios
			.get(`${config.url.API_URL}/getSubareaRef`)
			.then((res) => {
				console.log(res);
				setSubAreaRef(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getLevelCode = () => {
		axios
			.get(`${config.url.API_URL}/getLevelCode`)
			.then((res) => {
				console.log(res);
				setLevelCode(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// HANDLE ONCHANGE ACTION FOR EDITTING CURRENT ACTIVITY
	const handleChange = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setModalData({ ...modalData, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR EDITTING CURRENT ACTIVITY
	const handleFileChange = (e) => {
		console.log(e.target.files);
		setModalData({ ...modalData, [e.target.name]: e.target.files[0] });
	};

	// HANDLE FORM SUBMIT FOR EDITTING CURRENT ACTIVITY
	const handleSubmit = (e) => {
		e.preventDefault();
		setFormSubmitModal(true);
		// console.log(modalData);
		const data = new FormData();
		data.append("thumbnailfile", modalData.thumbnailfile);
		data.append("offlinepdfurlfile", modalData.offlinepdfurlfile);
		data.append("featuredimgfile", modalData.featuredimgfile);
		data.append("activityid", modalData.activityid);
		data.append("worksheetname", modalData.worksheetname);
		data.append("worksheetcode", modalData.worksheetcode);
		data.append("featuredimg", modalData.featuredimg);
		data.append("thumbnail", modalData.thumbnail);
		data.append("description", modalData.description);
		data.append("offlinepdfurl", modalData.offlinepdfurl);
		data.append("subarearef", modalData.subarearef);
		data.append("levelcode", modalData.levelcode);
		data.append("recommended", modalData.recommended);
		data.append("answersheeturl", modalData.answersheeturl);
		data.append("answersheeturlfile", modalData.answersheeturlfile);
		data.append("onlinepdfurl", modalData.onlinepdfurl);
		data.append("gameurl", modalData.gameurl);
		data.append("ratingid", modalData.ratingid);
		axios
			.post(
				`${config.url.API_URL}/updateActivity`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setFormSubmitModal(false);
				console.log(res);
				notifySuccess();
				window.location.href = "/admin/interactive";
			})
			.catch((err) => {
				setFormSubmitModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	const renderStatus = (datalet) => {
		if (
			datalet.featuredimg !== null &&
			datalet.featuredimg !== "null" &&
			datalet.featuredimg !== "NULL" &&
			datalet.featuredimg !== "" &&
			datalet.title !== null &&
			datalet.title !== "null" &&
			datalet.title !== "NULL" &&
			datalet.title !== "" &&
			datalet.description !== null &&
			datalet.description !== "null" &&
			datalet.description !== "NULL" &&
			datalet.description !== "" &&
			datalet.thumbnail !== null &&
			datalet.thumbnail !== "null" &&
			datalet.thumbnail !== "NULL" &&
			datalet.thumbnail !== "" &&
			datalet.offlinepdfurl !== null &&
			datalet.offlinepdfurl !== "null" &&
			datalet.offlinepdfurl !== "NULL" &&
			datalet.offlinepdfurl !== "" &&
			datalet.onlinepdfurl !== null &&
			datalet.onlinepdfurl !== "null" &&
			datalet.onlinepdfurl !== "NULL" &&
			datalet.onlinepdfurl !== "" &&
			datalet.answersheeturl !== null &&
			datalet.answersheeturl !== "null" &&
			datalet.answersheeturl !== "NULL" &&
			datalet.answersheeturl !== ""
		) {
			return <p>Complete</p>;
		} else if (
			datalet.featuredimg !== null &&
			datalet.featuredimg !== "null" &&
			datalet.featuredimg !== "NULL" &&
			datalet.featuredimg !== "" &&
			datalet.title !== null &&
			datalet.title !== "null" &&
			datalet.title !== "NULL" &&
			datalet.title !== "" &&
			datalet.description !== null &&
			datalet.description !== "null" &&
			datalet.description !== "NULL" &&
			datalet.description !== "" &&
			datalet.thumbnail !== null &&
			datalet.thumbnail !== "null" &&
			datalet.thumbnail !== "NULL" &&
			datalet.thumbnail !== "" &&
			datalet.offlinepdfurl !== null &&
			datalet.offlinepdfurl !== "null" &&
			datalet.offlinepdfurl !== "NULL" &&
			datalet.offlinepdfurl !== "" &&
			datalet.answersheeturl !== null &&
			datalet.answersheeturl !== "null" &&
			datalet.answersheeturl !== "NULL" &&
			datalet.answersheeturl !== ""
		) {
			return <p>Complete (No Interactive)</p>;
		} else {
			return <p>Incomplete</p>;
		}
	};

	const handleSortColumn = (sortColumn, sortType) => {
		setLoading(true);
		setTimeout(() => {
			setSortColumn(sortColumn);
			setSortType(sortType);
			setLoading(false);
		}, 500);
	};

	const handleChangePage = (datakey) => {
		setPage(datakey);
	};

	const handleChangeLength = (datakey) => {
		setPage(1);
		setDisplayLength(datakey);
	};

	const getData = () => {
		// const { data, sortColumn, sortType } = this.state;
		const data = displayData;

		if (sortColumn && sortType) {
			const filterdata = data.filter((v, i) => {
				const start = displayLength * (page - 1);
				const end = start + displayLength;
				return i >= start && i < end;
			});

			return filterdata.sort((a, b) => {
				let x = a[sortColumn];
				let y = b[sortColumn];
				if (typeof x === "string") {
					x = x.charCodeAt();
				}
				if (typeof y === "string") {
					y = y.charCodeAt();
				}
				if (sortType === "asc") {
					return x - y;
				} else {
					return y - x;
				}
			});
		}
	};

	useEffect(() => {
		getActivity();
		getSubarearef();
		getLevelCode();
	}, []);
	return (
		<div className="main_container" style={{}}>
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<>
					<div className="container" style={{}}>
						{/* <h3>Worksheet Dashboard</h3> */}
						{/* BUTTONS ROW */}
						<div className="row mb-2">
							{/* BUTTON FOR LAUNCHING MODAL FOR ADDING NEW ACTIVITIES */}
							<div className="col-sm-3">
								<div>
									<Button
										size="lg"
										color="primary"
										onClick={() => {
											toggleNewActivityModal();
										}}
									>
										Add Activity
									</Button>
								</div>
							</div>

							{/* BUTTON FOR LAUNCHING MODAL FOR ADDING BULK UPLOAD OF FILES */}
							<div className="col-sm-3">
								<div>
									<Button
										size="lg"
										color="primary"
										onClick={() => {
											toggleBulkUpModal();
										}}
									>
										Bulk Upload Files
									</Button>
								</div>
							</div>

							{/* BUTTON FOR LAUNCHING MODAL FOR INTERACTIVE FOLDER UPLOAD */}
							<div className="col-sm-3">
								<div>
									<Button
										size="lg"
										color="primary"
										onClick={() => {
											toggleInterFolderModal();
										}}
									>
										Interactive Folder Upload
									</Button>
								</div>
							</div>

							{/* BUTTON FOR UPLOADING EXCEL SHEETSHEET/CSV FILES WITH DB DATA */}
							<div className="col-sm-3">
								<Button
									size="lg"
									color="primary"
									onClick={() => {
										toggleCsvModal();
									}}
								>
									CSV File Upload
								</Button>
							</div>
						</div>
						{/* SHOW TOTAL WORKSHEET, COMPLETED AND UNCOMPLETED */}
						<div className="row mb-2">
							<div className="col-sm-3">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(data);
										}}
									>
										Available Worksheets:{" "}
										<span className="stat-spans" style={{}}>
											{totalWksht}
										</span>
									</Button>
								</div>
							</div>
							<div className="col-sm-3">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(completeWksheetData);
										}}
									>
										Completed Worksheets:{" "}
										<span className="stat-spans" style={{}}>
											{completeWithInteractiveWksht}
										</span>
									</Button>
								</div>
							</div>
							<div className="col-sm-3">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(
												completeWksheetNoInteractiveData
											);
										}}
									>
										Completed Worksheet(no interactive):{" "}
										<span className="stat-spans" style={{}}>
											{completeWithoutInteractiveWksht}
										</span>
									</Button>
								</div>
							</div>
							<div className="col-sm-3">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											setDisplayData(
												InCompleteWksheetData
											);
										}}
									>
										Incomplete Worksheets:{" "}
										<span className="stat-spans" style={{}}>
											{totalWksht -
												completeWithoutInteractiveWksht}
										</span>
									</Button>
								</div>
							</div>
						</div>

						{/* ROW FOR WORKSHEET DOWNLOAD */}
						<div className="row mb-3">
							<div className="col-sm-12 text-right">
								<div>
									<a
										href="https://nimdee.com/admin/api/downloadInteractiveCSV.php"
										target="_blank"
										rel="noreferrer"
									>
										<Button color="primary" size="lg">
											Download CSV
										</Button>
									</a>
								</div>
							</div>
						</div>
						{/* DATA TABLES THAT DISPLAYS ALL CURRENT ACTIVITIES */}
						<Table
							wordWrap
							data={getData()}
							sortColumn={sortColumn}
							sortType={sortType}
							onSortColumn={handleSortColumn}
							loading={loading}
							height={500}
							// autoHeight
						>
							<Column width={55} sortable fixed resizable>
								<HeaderCell>ID</HeaderCell>
								<Cell dataKey="activityid" />
							</Column>

							<Column width={200} sortable resizable>
								<HeaderCell>Code</HeaderCell>
								<Cell dataKey="worksheetcode" />
							</Column>

							<Column width={150} sortable resizable>
								<HeaderCell>Name</HeaderCell>
								<Cell dataKey="worksheetname"></Cell>
							</Column>

							<Column width={100} sortable resizable>
								<HeaderCell>SubArea</HeaderCell>
								<Cell dataKey="subarearef" />
							</Column>

							<Column width={100} sortable resizable>
								<HeaderCell>Levelcode</HeaderCell>
								<Cell dataKey="levelcode" />
							</Column>

							{/* <Column width={50} sortable resizable>
								<HeaderCell>Ratingid</HeaderCell>
								<Cell dataKey="ratingid"></Cell>
							</Column> */}

							<Column width={250} sortable resizable>
								<HeaderCell>Description</HeaderCell>
								<Cell dataKey="description"></Cell>
							</Column>

							<Column width={250} sortable resizable>
								<HeaderCell>Thumbnail</HeaderCell>
								<Cell dataKey="thumbnail"></Cell>
							</Column>

							<Column width={250} sortable resizable>
								<HeaderCell>FeaturedImg</HeaderCell>
								<Cell dataKey="featuredimg"></Cell>
							</Column>

							<Column width={250} sortable resizable>
								<HeaderCell>AnswersheetUrl</HeaderCell>
								<Cell dataKey="answersheeturl"></Cell>
							</Column>

							<Column width={250} sortable resizable>
								<HeaderCell>OfflinepdfUrl</HeaderCell>
								<Cell dataKey="offlinepdfurl"></Cell>
							</Column>

							<Column width={250} sortable resizable>
								<HeaderCell>OnlinepdfUrl</HeaderCell>
								<Cell dataKey="onlinepdfurl"></Cell>
							</Column>

							<Column width={100} sortable resizable>
								<HeaderCell>GameUrl</HeaderCell>
								<Cell dataKey="gameurl"></Cell>
							</Column>

							<Column width={120} sortable resizable>
								<HeaderCell>Create_date</HeaderCell>
								<Cell dataKey="create_date"></Cell>
							</Column>

							<Column width={80} fixed={"right"} resizable>
								<HeaderCell>Action</HeaderCell>
								<Cell>
									{(rowData) => {
										return (
											<>
												<Button
													size="md"
													color="primary"
													onClick={() => {
														setModalData(rowData);

														toggle();
													}}
												>
													Edit
												</Button>
											</>
										);
									}}
								</Cell>
							</Column>

							<Column width={100} fixed={"right"} resizable>
								<HeaderCell>Status</HeaderCell>
								<Cell>
									{(rowData) => {
										return renderStatus(rowData);
									}}
								</Cell>
							</Column>
						</Table>

						<Pagination
							lengthMenu={[
								{
									value: 10,
									label: 10,
								},
								{
									value: 20,
									label: 20,
								},
							]}
							activePage={page}
							displayLength={displayLength}
							total={displayData.length}
							onChangePage={handleChangePage}
							onChangeLength={handleChangeLength}
						/>

						{/* MODAL FOR EDITTING CURRENT ACTIVITIES */}
						<div>
							<Modal
								isOpen={modal}
								toggle={toggle}
								className=""
								style={{
									fontSize: "14px",
								}}
								scrollable={true}
							>
								<ModalHeader toggle={toggle}>
									Edit Activity
								</ModalHeader>
								<ModalBody>
									<Form
										onSubmit={handleSubmit}
										method="POST"
										encType="multipart/form-data"
									>
										<FormGroup row>
											<Label for="worksheetname" sm={3}>
												Worksheetname
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="worksheetname"
													id="worksheetname"
													defaultValue={
														modalData.worksheetname
													}
													onBlur={handleChange}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="worksheetcode" sm={3}>
												Worksheetcode
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="worksheetcode"
													id="worksheetcode"
													defaultValue={
														modalData.worksheetcode
													}
													onBlur={handleChange}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="subarearef" sm={3}>
												SubjectAreaCode
											</Label>
											<Col sm={9}>
												<Input
													type="select"
													name="subarearef"
													id="subarearef"
													defaultValue={
														modalData.subarearef
													}
													onBlur={handleChange}
												>
													{subarearef.map((refs) => (
														<option
															value={
																refs.subarearef
															}
														>
															{refs.subarea}
														</option>
													))}
												</Input>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="levelcode" sm={3}>
												LevelCode
											</Label>
											<Col sm={9}>
												<Input
													type="select"
													name="levelcode"
													id="levelcode"
													defaultValue={
														modalData.levelcode
													}
													onBlur={handleChange}
												>
													{levelcode.map((levs) => (
														<option
															value={
																levs.levelcode
															}
														>
															{levs.name}
														</option>
													))}
												</Input>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="description" sm={3}>
												Description
											</Label>
											<Col sm={9}>
												<Input
													type="textarea"
													name="description"
													id="description"
													defaultValue={
														modalData.description
													}
													onBlur={handleChange}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="onlinepdfurl" sm={3}>
												Interactive Worksheet
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="onlinepdfurl"
													id="onlinepdfurl"
													value={
														modalData.onlinepdfurl
													}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="thumbnail" sm={3}>
												Current Thumbnail
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="thumbnail"
													id="thumbnail"
													readonly
													value={modalData.thumbnail}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="thumbnailfile" sm={3}>
												Upload Thumbnail Image
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="thumbnailfile"
													id="thumbnailfile"
													accept=".svg"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Thumbnail
													Image (note: if thumbnail
													already exists this will
													override it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="featuredimg" sm={3}>
												Current Featuredimg
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="featuredimg"
													id="featuredimg"
													readonly
													value={
														modalData.featuredimg
													}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="featuredimgfile" sm={3}>
												Upload Featured Image
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="featuredimgfile"
													id="featuredimgfile"
													accept=".svg"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Featured Image
													(note: if Featured Image
													already exists this will
													override it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="answersheeturl" sm={3}>
												Current Answersheet
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="answersheeturl"
													id="answersheeturl"
													readonly
													value={
														modalData.answersheeturl
													}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label
												for="answersheeturlfile"
												sm={3}
											>
												Upload AnswerSheet
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="answersheeturlfile"
													id="answersheeturlfile"
													accept=".pdf"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Answer Sheet
													(note: if AnswerSheet
													already exists this will
													override it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label for="offlinepdfurl" sm={3}>
												Current Offlinepdf
											</Label>
											<Col sm={9}>
												<Input
													type="text"
													name="offlinepdfurl"
													id="offlinepdfurl"
													readonly
													value={
														modalData.offlinepdfurl
													}
												/>
											</Col>
										</FormGroup>

										<FormGroup row>
											<Label
												for="offlinepdfurlfile"
												sm={3}
											>
												Upload Offline Pdf
											</Label>
											<Col sm={9}>
												<Input
													type="file"
													name="offlinepdfurlfile"
													id="offlinepdfurlfile"
													accept=".pdf"
													onChange={handleFileChange}
												/>
												<FormText color="muted">
													Please upload Offline PDF
													(note: if Offline PDF
													already exists this will
													override it)
												</FormText>
											</Col>
										</FormGroup>

										<FormGroup check row>
											<Col sm={{ size: 5, offset: 4 }}>
												<Button
													size="md"
													color="primary"
													type="submit"
												>
													Submit
												</Button>
											</Col>
										</FormGroup>
									</Form>
								</ModalBody>
								<ModalFooter>
									<Button
										color="secondary"
										onClick={toggle}
										size="md"
									>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>
						</div>

						{/* MODAL FOR ADDING NEW ACTIVITIES */}
						<div>
							<AddActivityModal
								isOpen={newActivityModal}
								toggle={toggleNewActivityModal}
							/>
						</div>

						{/* MODAL FOR BULK FILE UPLOAD */}
						<div>
							<BulkFileUploadModal
								isOpen={bulkUpModal}
								toggle={toggleBulkUpModal}
							/>
						</div>

						{/* MODAL FOR UPLOAD OF INTERACTIVE FOLDERS */}
						<div>
							<InteractiveFolderUploadModal
								isOpen={interFolderModal}
								toggle={toggleInterFolderModal}
							/>
						</div>

						{/* MODAL FOR UPLOAD OF CSV FILE */}
						<div>
							<CSVFileUploadModal
								isOpen={csvModal}
								toggle={toggleCsvModal}
							/>
						</div>
					</div>
				</>
			)}

			{/* FORM SUBMIT LOADING MODAL */}
			<div>
				<LoadingModal
					isOpen={formSubmitModal}
					toggle={toggleFormSubmitModal}
				/>
			</div>
		</div>
	);
};

export default WorksheetDashboard;
