import React from "react";
import axios from "axios";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant";
import LoadingModal from "../../../layout/LoadingModal/LoadingModal";

const BulkStoryUploadModal = (props) => {
	const [bulkUpModalData, setBulkUpModalData] = React.useState({});
	const [loadingModal, setLoadingModal] = React.useState(false);
	const toggleLoadingModal = () => setLoadingModal(!loadingModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});
	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR BULK UPLOAD FORM
	const handleFileChange = (e) => {
		console.log(e.target.files);
		if (e.target.files.length <= 100) {
			setBulkUpModalData({
				...bulkUpModalData,
				[e.target.name]: e.target.files,
			});
		} else {
			notifyFailure(
				"You are only allowed to upload a maximum of 100 files at a time"
			);
		}
	};

	// HANDLE FORM SUBMIT FOR BULK FILES UPLOAD
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoadingModal(true);
		console.log(bulkUpModalData);
		const data = new FormData();

		if (bulkUpModalData.files) {
			for (const key of Object.keys(bulkUpModalData.files)) {
				data.append("files[]", bulkUpModalData.files[key]);
			}
		}

		// console.log(Object.keys(bulkUpModalData.files));
		// console.log(data);
		axios
			.post(
				`${config.url.API_URL}/bulkStoryBookUpload`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setLoadingModal(false);
				console.log(res);
				notifySuccess();
				window.location.href = "/admin/content/storybooks";
			})
			.catch((err) => {
				setLoadingModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};
	return (
		<>
			<Modal {...props} className="" style={{}}>
				<ModalHeader toggle={props.toggle}>
					Bulk Upload Files
				</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={handleSubmit}
						method="POST"
						encType="multipart/form-data"
					>
						<FormGroup row>
							<Label for="files" sm={3}>
								Upload Files (.png and .pdf format only)
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="files"
									id="files"
									multiple
									required
									accept=".png, .pdf"
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Files(note: if Files already
									exists this will overwrite them)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup check row>
							<Col sm={{ size: 5, offset: 4 }}>
								<Button color="primary" type="submit" size="md">
									Submit
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={props.toggle} size="md">
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{/* Loading Modal Pop Up */}
			<LoadingModal isOpen={loadingModal} toggle={toggleLoadingModal} />
		</>
	);
};

export default BulkStoryUploadModal;
