import classes from "./Sidebar.module.css";
// import NimdeeLogo from "../../../images/nimdee-logo.svg";
import NavItem from "../NavItem/NavItem";
import { ReactComponent as HomeIcon } from "../../../images/Home.svg";
// import { ReactComponent as UserIcon } from "../../../images/User.svg";
import { ReactComponent as OrderIcon } from "../../../images/Order.svg";
// import { ReactComponent as ChartIcon } from "../../../images/Chart.svg";
// import { ReactComponent as PagesIcon } from "../../../images/Chat_alt.svg";
import { ReactComponent as ReportIcon } from "../../../images/Credit_card.svg";
// import { ReactComponent as BellIcon } from "../../../images/Bell_pin.svg";
import { ReactComponent as ToolsIcon } from "../../../images/Setting_line.svg";
import { ReactComponent as MenuIcon } from "../../../images/menu.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import React from "react";

const Sidebar = () => {
	const [showSidebar, setShowSidebar] = React.useState(true);
	const toggleSidebar = () => setShowSidebar(!showSidebar);
	return (
		<section
			className={classes.Sidebar}
			style={{ width: `${showSidebar ? "20%" : "5%"}` }}
		>
			<nav>
				<div className={classes.LogoContainer}>
					{showSidebar && (
						<img
							src="../../../admin/images/nimdee-logo.png"
							alt="Nimdee Logo"
							className={classes.Logo}
						/>
					)}
					<button
						onClick={toggleSidebar}
						className={classes.SidebarBtn}
					>
						{showSidebar ? <CloseIcon /> : <MenuIcon />}
					</button>
				</div>
				<ul>
					<NavItem
						path="/admin"
						label="Home"
						sidebarShow={showSidebar}
					>
						<HomeIcon />
					</NavItem>

					<NavItem
						path="/admin/content"
						label="Content"
						sidebarShow={showSidebar}
						disabled
					>
						<OrderIcon />
						<>
							<NavItem
								path="/admin/interactive"
								label="Interactive"
								sidebarShow={showSidebar}
							/>
							<NavItem
								path="/admin/storybooks"
								label="Storybooks"
								sidebarShow={showSidebar}
							/>
							<NavItem
								path="/admin/images"
								label="Server Images"
								sidebarShow={showSidebar}
							/>
							<NavItem
								path="/admin/printables"
								label="Server Printables"
								sidebarShow={showSidebar}
							/>
						</>
					</NavItem>

					<NavItem
						path="/admin/content"
						label="Back2School"
						sidebarShow={showSidebar}
						disabled
					>
						<OrderIcon />
						<>
							<NavItem
								path="/admin/b2sDashboard"
								label="Dashboard"
								sidebarShow={showSidebar}
							/>
							<NavItem
								path="/admin/b2sSignUps"
								label="SignUp Summary"
								sidebarShow={showSidebar}
							/>
						</>
					</NavItem>

					<NavItem
						path="/admin/report"
						label="Report"
						sidebarShow={showSidebar}
						disabled
					>
						<ReportIcon />

						<NavItem
							path="/admin/site-login"
							label="Site Login"
							sidebarShow={showSidebar}
						/>
					</NavItem>

					<NavItem
						path="/admin/content"
						label="Downloads"
						sidebarShow={showSidebar}
						disabled
					>
						<OrderIcon />
						<>
							<NavItem
								path="/admin/workbookdownloads"
								label="WorkBooks"
								sidebarShow={showSidebar}
							/>
							<NavItem
								path="/admin/worksheetdownloads"
								label="WorkSheets"
								sidebarShow={showSidebar}
							/>
						</>
					</NavItem>

					<NavItem
						path="/admin/register"
						label="Register Account"
						sidebarShow={showSidebar}
					>
						<ToolsIcon />
					</NavItem>
				</ul>
			</nav>
		</section>
	);
};

export default Sidebar;
