import React from "react";
import { Modal } from "reactstrap";

const LoadingModal = (props) => {
	return (
		<div>
			<Modal
				{...props}
				className="text-center"
				backdrop={"static"}
				keyboard={false}
			>
				<div className="text-center img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
					<p>Upload In Progress</p>
				</div>
			</Modal>
		</div>
	);
};

export default LoadingModal;
