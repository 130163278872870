import axios from "axios";
import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import classes from "./Login.module.css";

const Login = () => {
	const [inputCode, setInputCode] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const handleChange = (e) => {
		const val = e.target.value;
		setInputCode(val);
	};

	const adminUser = sessionStorage.getItem("nimdee-admin");
	if (adminUser !== null) return <Redirect to="/admin" />;

	const handleSubmit = (e) => {
		e.preventDefault();

		if (inputCode === "") return setErrorMessage("Please enter your code");

		if (inputCode.length < 4)
			return setErrorMessage("Code should not be less than 4");

		const loginCode = JSON.stringify({
			code: inputCode,
		});

		setLoading(true);
		axios
			.post("https://nimdee.com/admin/api/api.php/adminLogin", loginCode)
			// .post("https://nimdee.serviceschoolhouse.com/adminLogin", loginCode)
			.then((res) => {
				// setInputCode('');
				// setErrorMessage('');
				const data = res.data.data;
				const adminInfo = {
					accountId: data.AdminAccountid,
					accountName: data.AdminAccountname,
					accountRole: data.AdminAccountrole,
				};
				sessionStorage.setItem(
					"nimdee-admin",
					JSON.stringify(adminInfo)
				);
				history.push("/");
			})
			.catch((err) => {
				setErrorMessage("Oops! Something went wrong!");
				setLoading(false);
				setInputCode("");
			});
	};
	return (
		<div className={classes.FormContainer}>
			<form className={classes.Form} onSubmit={handleSubmit}>
				<img
					src="../../../admin/images/nimdee-logo.png"
					alt="Nimdee Logo"
					className={classes.Logo}
				/>
				<input
					type="password"
					name="login"
					id="login"
					placeholder="Enter your Login Code"
					inputMode="numeric"
					size="4"
					maxLength="4"
					required
					value={inputCode}
					onChange={handleChange}
					onFocus={() => setErrorMessage("")}
				/>
				{errorMessage && <p>{errorMessage}</p>}
				<button type="submit" onClick={handleSubmit}>
					Submit
				</button>
				{loading && (
					<div className={classes.Loader}>
						<Loader />
					</div>
				)}
			</form>
		</div>
	);
};

export default Login;
