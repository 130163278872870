import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/layout/Sidebar/Sidebar";
import B2SDashboard from "./components/pages/BackToSchool/B2SDashboard/B2SDashboard";
import SignUpSummary from "./components/pages/BackToSchool/SignUpSummary/SignUpSummary";
import WorkBookDownloads from "./components/pages/Downloads/WorkBookDownloads/WorkBookDownloads";
import WorkSheetDownloads from "./components/pages/Downloads/WorkSheetDownloads/WorkSheetDownloads";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import Login from "./components/pages/Login/Login";
import LoginLog from "./components/pages/LoginLog/LoginLog";
import Register from "./components/pages/Register/Register";
import ServerImages from "./components/pages/ServerImages/ServerImages";
import ServerPrintables from "./components/pages/ServerPrintables/ServerPrintables";
import StoryBookDashboard from "./components/pages/StoryBookDashboard/StoryBookDashboard";
import WorksheetDashboard from "./components/pages/WorksheetDashboard/WorksheetDashboard";

function App() {
	// const adminInfo = JSON.parse(localStorage.getItem('nimdee-admin'));

	const ProtectedRoute = ({ path, ...otherProps }) => {
		// fetching session storage here to prevent an infinite loop when redirecting to page
		// fecthing seesion storage outside this function, would always made it null
		const adminInfo = JSON.parse(sessionStorage.getItem("nimdee-admin"));

		if (adminInfo === null) return <Redirect to="/admin/login" />;

		return <Route exact path={path} {...otherProps} />;
	};
	return (
		<div className="App">
			<Sidebar />
			<Switch>
				{" "}
				<ProtectedRoute exact path="/admin" component={LandingPage} />
				<Route exact path="/admin/login" component={Login} />
				<Route path="/admin/content" />
				<ProtectedRoute
					exact
					path="/admin/interactive"
					component={WorksheetDashboard}
				/>
				<ProtectedRoute
					exact
					path="/admin/storybooks"
					component={StoryBookDashboard}
				/>
				<ProtectedRoute
					exact
					path="/admin/images"
					component={ServerImages}
				/>
				<ProtectedRoute
					exact
					path="/admin/printables"
					component={ServerPrintables}
				/>
				<ProtectedRoute
					exact
					path="/admin/b2sDashboard"
					component={B2SDashboard}
				/>
				<ProtectedRoute
					exact
					path="/admin/b2sSignUps"
					component={SignUpSummary}
				/>
				<ProtectedRoute
					exact
					path="/admin/workbookdownloads"
					component={WorkBookDownloads}
				/>
				<ProtectedRoute
					exact
					path="/admin/worksheetdownloads"
					component={WorkSheetDownloads}
				/>
				<Route path="/admin/report" />
				<ProtectedRoute
					exact
					path="/admin/site-login"
					component={LoginLog}
				/>
				<ProtectedRoute
					exact
					path="/admin/register"
					component={Register}
				/>
				<Redirect to="/admin" />
			</Switch>
		</div>
	);
}

export default App;
