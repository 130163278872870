const prod = {
    url: {
        API_URL: "https://nimdee.com/backend/api/v1/",
        // API_URL: "https://nimdee.com/api.php",
    },
};
const dev = {
    url: {
        API_URL:
            "https://nimdee.com/backend/api/v1/",
        // API_URL: "https://afternoon-ridge-35420.herokuapp.com/https://nimdee.com/api.php"
    },
};

export const config2 = process.env.NODE_ENV === "development" ? dev : prod;
