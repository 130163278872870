import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavItem.module.css';
import CaretDown from '../../../images/caret-down.svg';

const NavItem = (props) => {
	const childrenArray = React.Children.toArray(props.children);
	const [subNav, showSubNav] = React.useState(false);
	const showSubMenu = () => showSubNav(true);
	const hideSubMenu = () => showSubNav(false);
	const exact = props.label === 'Home' ? true : false;

	const handleDisabledLink = (e) => {
		if (props.disabled) return e.preventDefault();
	};
	// console.log(childrenArray);
	return (
		<NavLink
			exact={exact}
			to={props.path}
			activeClassName={classes.Active}
			className={classes.NavItem}
			onMouseOver={showSubMenu}
			onMouseLeave={hideSubMenu}
			onClick={handleDisabledLink}
		>
			<div className={classes.MainMenu}>
				{childrenArray[0]}
				{props.sidebarShow && props.label}
				{props.sidebarShow && childrenArray.length > 1 && <img src={CaretDown} alt="Caret Down" />}
			</div>
			{props.sidebarShow && subNav && <div className={classes.SubMenu}>{childrenArray[1]}</div>}
		</NavLink>
	);
};

export default NavItem;
