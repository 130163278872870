import React from "react";
import axios from "axios";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant";
import LoadingModal from "../../../layout/LoadingModal/LoadingModal";

const CSVFileUploadModal = (props) => {
	const [csvModalData, setCsvModalData] = React.useState({});
	const [loadingModal, setLoadingModal] = React.useState(false);
	const toggleLoadingModal = () => setLoadingModal(!loadingModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});

	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR CSV FILE UPLOAD FORM
	const handleFileChange = (e) => {
		console.log(e.target.files);
		setCsvModalData({
			...csvModalData,
			[e.target.name]: e.target.files[0],
		});
	};

	// HANDLE FORM SUBMIT FOR CSV FILE UPLOAD
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoadingModal(true);
		console.log(csvModalData);
		const data = new FormData();
		if (csvModalData.excelsheet) {
			data.append("excelsheet", csvModalData.excelsheet);
		}

		axios
			.post(
				`${config.url.API_URL}/uploadActivityExcelsheet`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setLoadingModal(false);
				console.log(res);
				notifySuccess();
				// window.location.href = "/admin/dashboard";
			})
			.catch((err) => {
				setLoadingModal(false);
				if (err.response) {
					if (err.response.data.message) {
						notifyFailure(err.response.data.message);
					} else if (err.response.data.error) {
						notifyFailure(err.response.data.error[0]);
						notifyFailure(err.response.data.error[1]);
						if (err.response.data.error[1]) {
							// window.open('https://nimdeewksht.sandbox.9ijakids.com/admin/api/downloadLogFile.php', '_blank');
							window.open(
								"https://nimdee.com/admin/api/downloadLogFile.php",
								"_blank"
							);
						} else {
						}
					} else {
						notifyFailure("Opps an Error Occurred");
					}
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};
	return (
		<>
			<Modal {...props} className="" style={{}}>
				<ModalHeader toggle={props.toggle}>Upload CSV File</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={handleSubmit}
						method="POST"
						encType="multipart/form-data"
					>
						<FormGroup row>
							<Label for="foldername" sm={3}>
								Upload Files (.csv format only)
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="excelsheet"
									id="excelsheet"
									accept=".csv"
									required
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload only csv file format
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup check row>
							<Col sm={{ size: 5, offset: 4 }}>
								<Button color="primary" type="submit" size="md">
									Submit
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={props.toggle} size="md">
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{/* Loading Modal Pop Up */}
			<LoadingModal isOpen={loadingModal} toggle={toggleLoadingModal} />
		</>
	);
};

export default CSVFileUploadModal;
