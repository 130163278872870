import React from "react";
import axios from "axios";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	Col,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../../Constant";
import LoadingModal from "../../../layout/LoadingModal/LoadingModal";

const AddActivityModal = (props) => {
	const [modalData, setModalData] = React.useState({});
	const [loadingModal, setLoadingModal] = React.useState(false);
	const toggleLoadingModal = () => setLoadingModal(!loadingModal);

	const notifySuccess = () =>
		toast.success("Upload Successful!", {
			position: "top-center",
			autoClose: false,
		});
	const notifyFailure = (msg) =>
		toast.error(msg, {
			position: "top-center",
			autoClose: false,
		});
	// HANDLE FILE UPLOAD ONCHANGE ACTION FOR ADDING NEW ACTIVITY
	const handleFileChange = (e) => {
		console.log(e.target.files);
		setModalData({ ...modalData, [e.target.name]: e.target.files[0] });
	};

	// HANDLE ONCHANGE ACTION FOR ADDING NEW ACTIVITY
	const handleChange = (e) => {
		const valueName = e.target.name;
		const value = e.target.value;
		setModalData({ ...modalData, [valueName]: value });
		// console.log(valueName);
	};

	// HANDLE FORM SUBMIT FOR ADDING NEW ACTIVITY
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoadingModal(true);
		console.log(modalData);
		const data = new FormData();
		data.append("thumbnailfile", modalData.thumbnailfile);
		data.append("offlinepdfurlfile", modalData.offlinepdfurlfile);
		data.append("featuredimgfile", modalData.featuredimgfile);

		data.append("worksheetname", modalData.worksheetname);
		data.append("worksheetcode", modalData.worksheetcode);

		data.append("description", modalData.description);

		data.append("subarearef", modalData.subarearef);
		data.append("levelcode", modalData.levelcode);

		data.append("answersheeturlfile", modalData.answersheeturlfile);

		axios
			.post(
				`${config.url.API_URL}/addActivity`,

				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((res) => {
				setLoadingModal(false);
				console.log(res);
				notifySuccess();
				window.location.href = "/admin/dashboard";
			})
			.catch((err) => {
				setLoadingModal(false);
				if (err.response) {
					notifyFailure(err.response.data.message);
				} else {
					console.log(err.response);
					notifyFailure("Opps an Error Occurred");
				}
			});
	};

	return (
		<>
			{" "}
			<Modal {...props} style={{}} scrollable={true}>
				<ModalHeader toggle={props.toggle}>Add Activity</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={handleSubmit}
						method="POST"
						encType="multipart/form-data"
					>
						<FormGroup row>
							<Label for="worksheetname" sm={3}>
								Worksheetname
							</Label>
							<Col sm={9}>
								<Input
									type="text"
									name="worksheetname"
									id="worksheetname"
									required
									defaultValue={modalData.worksheetname}
									onBlur={handleChange}
								/>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="worksheetcode" sm={3}>
								Worksheetcode
							</Label>
							<Col sm={9}>
								<Input
									type="text"
									name="worksheetcode"
									id="worksheetcode"
									required
									pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}.{2,}"
									defaultValue={modalData.worksheetcode}
									onBlur={handleChange}
								/>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="description" sm={3}>
								Description
							</Label>
							<Col sm={9}>
								<Input
									type="textarea"
									name="description"
									id="description"
									required
									defaultValue={modalData.description}
									onBlur={handleChange}
								/>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="thumbnailfile" sm={3}>
								Upload Thumbnail Image
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="thumbnailfile"
									id="thumbnailfile"
									required
									accept=".svg"
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Thumbnail Image (note: if
									thumbnail already exists this will override
									it)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="featuredimgfile" sm={3}>
								Upload Featured Image
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="featuredimgfile"
									id="featuredimgfile"
									required
									accept=".svg"
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Featured Image (note: if
									Featured Image already exists this will
									override it)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="answersheeturlfile" sm={3}>
								Upload AnswerSheet
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="answersheeturlfile"
									id="answersheeturlfile"
									required
									accept=".pdf"
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Answer Sheet (note: if
									AnswerSheet already exists this will
									override it)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup row>
							<Label for="offlinepdfurlfile" sm={3}>
								Upload Offline Pdf
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									name="offlinepdfurlfile"
									id="offlinepdfurlfile"
									required
									accept=".pdf"
									onChange={handleFileChange}
								/>
								<FormText color="muted">
									Please upload Offline PDF (note: if Offline
									PDF already exists this will override it)
								</FormText>
							</Col>
						</FormGroup>

						<FormGroup check row>
							<Col sm={{ size: 5, offset: 4 }}>
								<Button color="primary" type="submit" size="md">
									Submit
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={props.toggle} size="md">
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{/* Loading Modal Pop Up */}
			<LoadingModal isOpen={loadingModal} toggle={toggleLoadingModal} />
		</>
	);
};

export default AddActivityModal;
