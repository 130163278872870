const prod = {
	url: {
		// API_URL: "https://nimdee.com/admin/api/api.php",
		// API_URL: "https://nimdee.serviceschoolhouse.com",
		// API_URL: "https://nimdee.com/api.php",
		API_URL: "https://nimdee.com/admin/api/api.php",
	},
};
const dev = {
	url: {
		API_URL:
			"https://afternoon-ridge-35420.herokuapp.com/https://nimdee.com/admin/api/api.php",
		// API_URL: "https://afternoon-ridge-35420.herokuapp.com/https://nimdee.com/api.php"
	},
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
