import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { config } from "../../../Constant";

const LoginLog = () => {
	const { Column, HeaderCell, Cell } = Table;
	const [isloading, setIsloading] = useState(false);
	const [logData, setLogData] = useState([]);

	const getLoginLog = () => {
		setIsloading(true);
		axios
			.get(`${config.url.API_URL}/getAdminLoginLog`)
			.then((res) => {
				setLogData(res.data.data);
				setIsloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getLoginLog();
	}, []);
	return (
		<div className="main_container" style={{}}>
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<div className="container">
					<div className="row mb-2 text-center">
						<div className="col-12">
							<h3>Login Log</h3>
						</div>
					</div>
					<div
						className="row text-center"
						style={{
							height: "100vh",
						}}
					>
						<div className="col-8 mx-auto my-auto">
							<Table data={logData} height={500}>
								<Column width={200} sortable fixed resizable>
									<HeaderCell>Name</HeaderCell>
									<Cell dataKey="name" />
								</Column>

								<Column width={200} sortable fixed resizable>
									<HeaderCell>Role</HeaderCell>
									<Cell dataKey="role" />
								</Column>

								<Column width={200} sortable fixed resizable>
									<HeaderCell>Login At</HeaderCell>
									<Cell dataKey="login at" />
								</Column>
							</Table>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LoginLog;
