import React, { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Badge,
} from "reactstrap";
import { toast } from "react-toastify";
import "./workbookdownloads.css";
import "react-toastify/dist/ReactToastify.css";
import { config2 } from "../../../../Constant3";
//Material UI table
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

toast.configure();

const WorkBookDownloads = () => {
    //base url
    let url = config2.url.API_URL;
    const [isloading, setIsloading] = useState(false);
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [existingData, setExistingData] = useState([]);
    const [AllWorkBooks, setAllWorkBooks] = useState(0);
    const [filteredData, setFilteredData] = useState([...data]);
    const [checked, setChecked] = useState(false);

    const filterValue = value => {
        if (value) {
            const filtered = data.filter(d => d.email.trim().length > 0);
            setData(filtered);
        } else {
            setData([...data]);
        }
        setChecked(value);
    };

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => (
            <DeleteOutline {...props} ref={ref} />
        )),
        DetailPanel: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
        )),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
        )),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => (
            <ViewColumn {...props} ref={ref} />
        )),
    };

    const getNewUsers = () => {
        const date = new Date();
        console.log(date);
        setIsloading(true);
        axios
            .get(`${url}/WorkBook/workBook.php/GetWorkBookLog?page_number=${1}&page_size=${100}&filter="new"&start_date="2021-03-06"&end_date="2022-03-08"`)
            .then((res) => {
                console.log(res.data.data);
                setData(res.data.data);
                setAllWorkBooks(res.data.data.length);
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getExistingUsers = () => {
        setIsloading(true);
        axios
            .get(`${url}/WorkBook/workBook.php/GetWorkBookLog?page_number=${1}&page_size=${100}`)
            .then((res) => {
                console.log(res.data.data);
                setData(res.data.data);
                setAllWorkBooks(res.data.data.length);
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        const getAllWorkBooks = () => {
            setIsloading(true);
            axios
                .get(`${url}/WorkBook/workBook.php/GetWorkBookLog?page_number=${1}&page_size=${100}`)
                .then((res) => {
                    console.log(res.data.data);
                    setData(res.data.data);
                    setAllWorkBooks(res.data.data.length);
                    setIsloading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        getAllWorkBooks();
    }, []);

    return (
        <div className="b2smain_container">
            {isloading ? (
                <div className="textcenter img-fluid">
                    <img
                        src="../../../admin/images/Gear_Loading_Gif.gif"
                        alt="Loading GIF"
                    ></img>
                </div>
            ) : (
                <>
                    {/* SHOW TOTAL workbook*/}
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <div>
                                    <Button
                                        color="primary"
                                        size="lg"
                                        outline
                                        onClick={() => {
                                        }}
                                    >
                                        Total number of WorkBooks Downloaded:
                                        <Badge
                                            color="dark"
                                            style={{
                                                fontSize: "1rem",
                                            }}
                                        >
                                            {AllWorkBooks}
                                        </Badge>
                                    </Button>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* </div> */}

                    <MaterialTable
                        icons={tableIcons}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                        }}
                        columns={[
                            {
                                title: "NAME", field: "name",
                                // filterComponent: props => {
                                //     console.log("Props: ", props);
                                //     <FormControlLabel
                                //         control={
                                //             <Checkbox
                                //                 checked={checked}
                                //                 color="primary"
                                //                 onChange={e => filterValue(e.target.checked)}
                                //             />
                                //         }
                                //         label="NEW"
                                //         labelPlacement="end"
                                //     />
                                // }
                            },
                            { title: "EMAIL", field: "email" },
                            {
                                title: "Date Created",
                                field: "create_date",
                                filtering: true,
                            },
                        ]}
                        options={{
                            filtering: true
                        }}
                        data={data.map((item) => ({ ...item }))}
                        title="List of Workbooks"
                    />
                </>
            )}
        </div>
    );
};

export default WorkBookDownloads;
