import React, { useEffect, useState, forwardRef } from "react";
import axios from "axios";
import { Button, Badge } from "reactstrap";
import "./ServerPrintables.css";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../Constant";
//Material UI table
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";

const ServerPrintables = () => {
	//base url
	var url = config.url.API_URL;
	const [isloading, setIsloading] = useState(false);
	const [data, setData] = useState([]);
	const [totalPdfs, setTotalPdfs] = useState(0);

	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Delete: forwardRef((props, ref) => (
			<DeleteOutline {...props} ref={ref} />
		)),
		DetailPanel: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		FirstPage: forwardRef((props, ref) => (
			<FirstPage {...props} ref={ref} />
		)),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		NextPage: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		PreviousPage: forwardRef((props, ref) => (
			<ChevronLeft {...props} ref={ref} />
		)),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		SortArrow: forwardRef((props, ref) => (
			<ArrowDownward {...props} ref={ref} />
		)),
		ThirdStateCheck: forwardRef((props, ref) => (
			<Remove {...props} ref={ref} />
		)),
		ViewColumn: forwardRef((props, ref) => (
			<ViewColumn {...props} ref={ref} />
		)),
	};

	useEffect(() => {
		const getUser = () => {
			setIsloading(true);
			axios
				.get(`${url}/getServerPdfs`)
				.then((res) => {
					// console.log(res.data.data);
					setData(res.data.pdfFiles);
					// setDisplayData(res.data.data);
					setTotalPdfs(res.data.pdfFiles.length);
					setIsloading(false);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getUser();
	}, [url]);

	return (
		<div className="ServerPrintables_main_container">
			{isloading ? (
				<div className="textcenter img-fluid">
					<img
						src="../../../admin/images/Gear_Loading_Gif.gif"
						alt="Loading GIF"
					></img>
				</div>
			) : (
				<>
					{/* SHOW TOTAL FILES ON SERVER */}
					<div className="container">
						<div className="row mb-2">
							<div className="col-sm-6 mx-auto">
								<div>
									<Button
										color="primary"
										size="lg"
										outline
										onClick={() => {
											// setDisplayData(data);
										}}
									>
										Number of PDF Files on Server:{" "}
										<Badge
											color="dark"
											style={{
												fontSize: "1rem",
											}}
										>
											{totalPdfs}
										</Badge>
									</Button>
								</div>
							</div>
						</div>
					</div>

					<MaterialTable
						icons={tableIcons}
						options={{
							exportButton: true,
							exportAllData: true,
						}}
						columns={[
							{
								title: "ID",
								field: "count",
								type: "string",
							},
							{
								title: "FileName",
								field: "files",
								type: "string",
							},

							{
								title: "Upload Date",
								field: "uploadTime",
								// type: "date",
							},
						]}
						data={data.map((item) => ({ ...item }))}
						title="Pdf Files on Server"
					/>
				</>
			)}
		</div>
	);
};

export default ServerPrintables;
